(function ($) {

    $.fn.urlParam = function () {

        let getUrlParameter = function (sParam) {
            var sPageURL = window.location.search.substring(1),
                sURLVariables = sPageURL.split('&'),
                sParameterName,
                i;
        
            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=');
        
                if (sParameterName[0] === sParam) {
                    return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
                }
            }
        };

        // validate Parameter before writing into the input fields
        if (getUrlParameter('dateOfBirth') !== undefined) {

            if (_.validateDate(getUrlParameter('dateOfBirth'))) {
                $('.form-group-bdate input').val(_.changeFormateDateUser(getUrlParameter('dateOfBirth')));
            }
        }

        if (getUrlParameter('insuranceNumber') !== undefined) {

            if (_.validateTokenHelsana(getUrlParameter('insuranceNumber'))) {
                $('.form-group-token input:text').val(getUrlParameter('insuranceNumber'));
            }
        }

    };

}(jQuery));
