import URLWatcher from "../../../libs/v2/diga/scripts/URLWatcher";

(function ($) {

  $(document).ready(function () {

    // ********** jQuery plugins ************ 

    $('.jumbotron ul li p, .passage-hero ul li p').each(function (index) {
      var element = $(this);
      var tick = $('<span></span>').addClass('glyphicons glyphicons-tick');
      element.prepend(tick);
    });

    if ($('body.page_2').length > 0 || $('body.page_3').length > 0) {
      $('body').bobFormVoucherCss();
    }

    if ($('body.page_1').length > 0) {
      $('body').bobFormContact();
    }

    if ($('body.page_4').length > 0) {
      $('body').urlParam();
      $('body').bobFormVoucherHms();
    }




    // $(window).typeAnimation();

    // $('body').bobFormVoucher();

    $('body').scrollToAnchors();

    // $('#bottom').bottomize();

    $('.glyphicons').glyphClone();

    $('.passage a[title^=button], .footer a[title^=button], .stripe a[title^=button]').mdLinkToButton();

    $('.footer img').mdImageSize();

    $('body').bobButton();

    $('.body-content').linkTap();

    $(window).scrollTopArrow();

    $('body').fadePage();

    $('.bob-frame-show').bobframe();

    new URLWatcher();
  });

}(jQuery));