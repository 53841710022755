(function ($) {

    $.fn.bobFormVoucherHms = function () {

        // console.log("hms");

        var element = this; // body

        var msg = null;

        var successTimeout = null;

        var bobMsgHide = function (fade = true) {
            // if(!duration) {
            //   duration = 0;
            // }
            let duration = (fade) ? 200 : 0;
            $('.bob-msg').fadeOut(duration, function () {
                $('.bob-msg .msg-content').hide();
            });
            msg = null;
        };

        var bobMsgSuccess = function () {
            msg = 'success';
            bobMsgShow(msg);
            successTimeout = window.setTimeout(bobMsgHide, 3000, 200);

            // bobHide();
            var event = $.Event('dohide.bobframe');
            $('.bob-frame').trigger(event);
        };

        var bobMsgWarning = function () {
            msg = 'warning';
            bobMsgShow(msg);
        };

        var bobMsgError = function () {
            msg = 'error';
            bobMsgShow(msg);
        };

        var bobMsgShow = function (msg) {
            clearTimeout(successTimeout);
            $('.bob-msg .msg-' + msg).show();
            $('.bob-msg').fadeIn(200);
        }

        var sendData = function (e) {

            e.preventDefault();

            $('#submit', '.bob-frame').prop('disabled', true);
            $('.form-group input', '.bob-frame').removeClass('is-invalid');

            bobMsgHide(false);

            var data = {};
            var label = {};
            $(':input', e.target).each(function () {
                data[this.name] = $(this).val();
                label[this.name] = $("label[for='" + $(this).attr('id') + "']", e.target).text();
            });

            // data.bdate = ChangeBackFormateDate($('.form-group-bdate input').val());
            let userDateInput = _.normalizeInput($('.form-group-bdate input').val());

            var notValid = false;

            if (_.validateTokenHelsana(data.token)) {
                $('#inputToken').removeClass('is-invalid');
            } else {
                $('#inputToken').addClass('is-invalid');
                // errmsg += $('.form-group-token').data('errmsg');
                notValid = true;
            }

            // first see if the date is an actual date
            if (userDateInput === "" || !_.validateDate($('.form-group-bdate input').val())) {
                $('.form-group-bdate input').addClass('is-invalid');
                notValid = true;
            } else {
                let dateObj = new Date(_.changeFormateDateIso(userDateInput))
                let dateIsoString = dateObj.toISOString().substring(0, 10);

                if (_.changeFormateDateUser(dateIsoString) !== userDateInput) {
                    $('.form-group-bdate input').addClass('is-invalid');
                    notValid = true;
                } else {
                    $('.form-group-bdate input').removeClass('is-invalid');
                    data.bdate = _.changeFormateDateIso(dateIsoString);
                }
            }

            if ($('#customCheck1').is(':checked')) {
                data.description = $('#customCheck1').siblings('label').text() + "\n\n" + data.description;
                $('#customCheck1').removeClass('is-invalid');
            } else {
                $('#customCheck1').addClass('is-invalid');
                notValid = true;
            }

            if ($('#customCheck2').is(':checked')) {
                $('#customCheck2').removeClass('is-invalid');
            } else {
                $('#customCheck2').addClass('is-invalid');
                notValid = true;
            }

            if (notValid) {
                // enable button again
                $('#submit', '.bob-frame').prop('disabled', false);
                bobMsgWarning();
                return;
            }

            var lang = document.documentElement.lang;

            $.ajax({
                url: '/broca/v2/voucher?referrer=hms',
                type: 'POST',
                timeout: 0,
                contentType: "application/x-www-form-urlencoded",
                success: function (response) {
                    // console.log(response);
                    // google tag manager

                    // base64 encode variable
                    let params = btoa(JSON.stringify({
                        "hms_token": data.token,
                        "dateOfBirth": data.bdate
                    }));
                    
                    // compose redirect location
                    var loc = response.endpoint + lang + "/registration/" + response.voucher;
                    loc = loc + "?v=" + params;
                    // redirect to registration
                    // window.location.href = loc;

                    // open bob-frame with content from content-confirm
                    $('.bob-frame-show').trigger('click');

                    // attach the voucherized href to the button within the recently opened bob-frame
                    $('#bob-frame-confirm-btn').prop('href', loc);

                    $('document').spinner('hide');

                },


                error: function (err) {
                    // console.log(err);
                    // var errmsg = $('form.form-voucher').data('errmsg');
                    bobMsgError();
                    // enable button again
                    $('#btn-submit', 'form.form-voucher').prop('disabled', false);
                    $('document').spinner('hide');
                }
            });

        };

        $('.bob-frame').on('hide.bobframe', function () {
            // if bobframe is closed, clear up some things
            // 1. remaining msg boxes
            if (msg === 'error' || msg === 'warning') {
                bobMsgHide();
            }
            // 2. remove red from inputs
            // $('.form-group input').removeClass('is-invalid');
        });

        $(document).off('.voucher');

        $(document).on('submit.voucher', 'form.form-voucher', function (e) {
            sendData(e);
        });

        $(document).on('click.voucher', '.bob-msg', function () {
            bobMsgHide();
        });

        $(document).on('click.voucher', '.bob-frame .modal-close', function () {
            bobMsgHide();
            var event = $.Event('dohide.bobframe');
            $('.bob-frame').trigger(event);
        });

        return this;

    };

}(jQuery));